import { LogLevel } from "@azure/msal-browser";
import { MSAL_CLIENT_ID, MSAL_DOMAIN_NAME, MSAL_POLICY_NAME } from "@brinks/common/utils/Config";
import logger from "./log4jsConfig";

const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority:`https://${MSAL_DOMAIN_NAME}.b2clogin.com/${MSAL_DOMAIN_NAME}.onmicrosoft.com/${MSAL_POLICY_NAME}`, 
    }
  },
  authorityDomain: `${MSAL_DOMAIN_NAME}.b2clogin.com`
}

export const msalConfig = {
  auth: {
    clientId: `${MSAL_CLIENT_ID}`, // This is the ONLY mandatory field that youneed to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If 'true', will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    pollIntervalMilliseconds: 0, // Sets the intervaltiming between authentication redirects/popups. This is important to not let the application render inside the popup window
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        logger.debug(JSON.stringify({
          LogLevel: level,
          Message: message
        }));
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
}
