// --------------------------------------------------------------------------------
//  EXTERNAL IMPORTS
// --------------------------------------------------------------------------------
import "./Dashboard.scss";

import TagManager from "react-gtm-module";

import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { Box, Flex, Text, Divider, Card } from "theme-ui";

// --------------------------------------------------------------------------------
//  INTERNAL IMPORTS
// --------------------------------------------------------------------------------
import NoDataCard from "./NoDataCard";
import Loader from "../../Shared/Loader";
import Button from "../../Shared/Button";
import FilterOverlay from "../AdvanceFilters";
import FilterTab from "./../../Shared/FilterTab";
import ButtonPills from "./../../Shared/ButtonPills";
import TransactionList from "../TransactionList/transactionList";
import DashboardBarChart from "./DashboardChart/DashboardBarChart";
import DashboardLineChart from "./DashboardChart/DashboardLineChart";

import { Api } from "@brinks/common/api/Api";
import { Transaction } from "@brinks/common/api/Api";
import { ExportIcon } from "@brinks/common/Icons/Components/export";
import { FilterIcon } from "@brinks/common/Icons/Components/filter";
import { TickIcon } from "@brinks/common/Icons/Components/tick-color";
import { getAccessToken, getFilterParams } from "@brinks/common/utils";
import { CaretDownIcon } from "@brinks/common/Icons/Components/caret-down";
import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { resetWebFilter } from "@brinks/common/reducers/storeLocationSlice";
import { transactionTypeData } from "@brinks/common/constants/transactionTypeData";
import { transactionFilterData } from "@brinks/common/constants/transactionTypeData";
import {
  resetFilter,
  getTransactions,
  DashboardSliceState,
  TransactionFilterType,
  setTransactionTypeFilter,
  setSelectedPaymentTypeFilter,
  setCurrentMerchant,
} from "@brinks/common/reducers/dashboardSlice";
import { getMerchants } from "@brinks/common/reducers/merchantsDetailSlice";
import useGetUserRole from "../../Hooks/useGetUserRole";


export default function Dashboard() {
  //Azure B2C Integration starts here
  let accessToken: string | undefined | null;
  const userRole: string = useGetUserRole(true);

  //IIFE
  (async () => {
    accessToken = await getAccessToken();
  })();
  //Azure B2C Integration ends here

  const boxRef: any = useRef();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [width, setWidth] = useState();
  const [open, setOpen] = useState(false);
  const [showEmpty, setShowEmpty] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterPanel, setFilterPanel] = useState(false);

  const reduxState = useAppSelector((
    state: { dashboardSlice: DashboardSliceState }
  ) => state.dashboardSlice);

  const query = getFilterParams({ ...reduxState });

  const { loadingMerchants, merchants } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );

  useEffect(() => {
    dispatch(getMerchants({ userType: userRole }));
  }, []);

  useEffect(() => {
    if (merchants)
      dispatch(setCurrentMerchant(merchants[0]));
  }, [merchants]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Dashboard | Overview"
      }
    })
  }, []);

  const {
    label,
    loading,
    datasets,
    totalAmount,
    transactions,
    noOfTransactions,
    transactionsFromBackend,
    selectedQuickTimeFilter,
    selectedPaymentTypeFilter,
    selectedTransactionFilterType,
    transactionAvailabilityByTransactionType,
  } = reduxState;

  const [transactionTypes, setTransactionTypes] = useState(selectedPaymentTypeFilter);
  const [transactionFilterType, setTransactionFilterType] = useState(selectedTransactionFilterType);

  useEffect(() => {
    setTransactionTypes(selectedPaymentTypeFilter);
  }, [selectedPaymentTypeFilter]);

  useEffect(() => {
    if (isFiltered) {
      if (transactionsFromBackend.length > 0) {
        setShowEmpty(false);
        setIsFiltered(false);
      } else {
        setShowEmpty(true);
      }
    }
  }, [transactionsFromBackend]);

  // Get 'width' and 'height' after the initial render and every time the list changes
  useEffect(() => {
    getScreenSize();
  }, []);

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    window.addEventListener("resize", getScreenSize);

    return () => window.removeEventListener("resize", getScreenSize);
  }, []);

  const setActive = (paymentTypeFilter: string): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Action: paymentTypeFilter,
        Page_title: "Dashboard | Overview - Filter",
      }
    });

    setTransactionTypes(paymentTypeFilter);

    dispatch(
      getTransactions({
        ...reduxState,
        userType: userRole,
        selectedPaymentTypeFilter: paymentTypeFilter,
      })
    );

    dispatch(
      setSelectedPaymentTypeFilter(
        paymentTypeFilter.toLocaleLowerCase()
      )
    );
  };

  const setFilterCard = (filterType: TransactionFilterType): void => {
    if (filterType.type === "number_transaction") {
      TagManager.dataLayer({
        dataLayer: {
          event: "Click_event",
          Action: "Number of transactions",
          Page_title: "Dashboard | Overview - Filter",
        }
      });
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "Click_event",
          Action: "Transaction amount",
          Page_title: "Dashboard | Overview - Filter",
        }
      });
    }

    setTransactionFilterType(filterType);
    dispatch(setTransactionTypeFilter(filterType));
    setActive(transactionTypes);
    setOpen(false);
  };

  const resetFilters = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Dashboard | No data card",
        Action: "Reset"
      }
    });

    dispatch(resetFilter());
    dispatch(resetWebFilter());

    setShowEmpty(false);
    setIsFiltered(false);
  };

  const handleDownload = async () => {
    const accessToken = await getAccessToken();
    const queryString = new URLSearchParams(query as any).toString();
    const downloadUrl = `${new Api()?.instance.defaults.baseURL}/transactions/export?${queryString}`;

    const res = await fetch(downloadUrl, { headers: { Authorization: `Bearer ${accessToken}` } } as any);

    const data = await res.text();
    const blobURL = window.URL.createObjectURL(new Blob([data], { type: "data:text/csv;charset=utf-8," }));

    // Create new tag for download file
    const anchor = document.createElement("a");
    anchor.download = "transactions.csv";
    anchor.href = blobURL;

    anchor.dataset.downloadurl = [
      "text/csv",
      anchor.download,
      anchor.href,
    ].join(":");

    anchor.click();
    // Remove URL.createObjectURL. The browser should not save the reference to the file.
    // For Firefox it is necessary to delay revoking the ObjectURL
    setTimeout(() => { URL.revokeObjectURL(blobURL) }, 100);
  };

  const getScreenSize = () => {
    const newWidth = boxRef.current?.clientWidth;

    setWidth(newWidth);
  };

  if (showEmpty) {
    return (
      <>
        <NoDataCard
          loading={loading === true}
          resetFilters={() => resetFilters()}
          setIsFiltered={(data) => setIsFiltered(data)}
          setFilterPanel={(data) => setFilterPanel(data)}
        />
        {filterPanel && (
          <FilterOverlay
            setFilterPanel={() => setFilterPanel(false)}
            setIsFiltered={(data) => setIsFiltered(data)}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Box mx={63} mt={39} data-testid="container">
        <Card
          bg={"white"}
          data-testid="card-1"
          sx={{
            margin: "0 auto",
            alignItems: "center",
            borderRadius: "16px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="card-header" data-testid="card-header">
            <div
              className="card-transaction-amount"
              data-testid="card-transaction-amount"
            >
              <Flex
                data-testid="flex-trans"
                sx={{ flexDirection: "column" }}
              >
                <Flex
                  data-testid="flex-trans-heading"
                  sx={{ mb: 2, cursor: "pointer", alignItems: "center" }}
                >
                  <Text
                    data-testid="flex-trans-amount"
                    onClick={() => { setOpen((open) => !open) }}
                    sx={{
                      pr: 2,
                      color: "shade_400",
                      fontFamily: "body",
                      fontSize: "subText",
                      lineHeight: "19.6px",
                    }}
                  >
                    {transactionFilterType?.action === "count"
                      ? t("Dashboard.number_of_transactions")
                      : t("Dashboard.transaction_amount")}
                  </Text>
                  <span onClick={() => { setOpen((open) => !open) }}>
                    <CaretDownIcon color={"shade_400"} />
                  </span>
                </Flex>
                <Text
                  data-testid="flex-trans-total"
                  sx={{
                    fontSize: "large",
                    lineHeight: "35.2px",
                    fontWeight: "heading",
                    fontFamily: "heading",
                  }}
                >
                  {
                    transactionFilterType?.action == "count"
                      ? noOfTransactions
                      : totalAmount
                  }
                </Text>
              </Flex>
              {open && (
                <Card
                  bg="white"
                  data-testid="card-no-trans"
                  sx={{
                    zIndex: 999,
                    top: "124px",
                    width: "289px",
                    height: "124px",
                    borderRadius: "8px",
                    border: "1px solid",
                    position: "absolute",
                    borderColor: "shade_200",
                    boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Flex
                    data-testid="card-no-trans-flex"
                    sx={{
                      pl: "24px",
                      pt: "24px",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    {transactionFilterData.map((data, key) => (
                      <Flex
                        key={key}
                        onClick={() => setFilterCard(data)}
                        data-testid="card-no-trans-flex-tick1"
                        sx={{
                          pr: "12px",
                          alignItems: "baseline",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          data-testid="flex-trans-amount"
                          sx={{
                            pt: "12px",
                            cursor: "pointer",
                            fontFamily: "body",
                            lineHeight: "22.4px",
                            color: (
                              transactionFilterType.type === data.type
                                ? `royalBlue_500`
                                : `body`
                            ),
                          }}
                        >
                          {t(data.label)}
                        </Text>
                        <Box data-testid="box-tick">
                          {transactionFilterType.type === data.type && (
                            <TickIcon color={"royalBlue_500"} />
                          )}
                        </Box>
                      </Flex>
                    ))}
                  </Flex>
                </Card>
              )}
            </div>
            <div className="filters" data-testid="filters">
              <FilterTab />
            </div>
            <div className="filter-option" data-testid="filter-option">
              <Button
                variant="outline"
                data-testid="btn-filter"
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      Action: "Filter",
                      event: "Click_event",
                      Page_title: "Dashboard | Overview - Filter",
                    }
                  });

                  TagManager.dataLayer({
                    dataLayer: {
                      event: "Click_event",
                      Action: "Open filter options",
                      Page_title: "Dashboard | Filter options",
                    }
                  });

                  setFilterPanel(true);
                  setIsFiltered(false);

                  document
                    .querySelector("#overlay")
                    ?.classList.add("overlay");

                  document.body.style.overflow = "hidden";
                }}
              >
                <FilterIcon color={"primary"} />{" "}
                <Text
                  pl={2}
                  color="royalBlue_500"
                  data-testid="btn-filter-txt"
                >
                  {t("Dashboard.filter")}
                </Text>
              </Button>
            </div>
          </div>
          <Box
            ref={boxRef}
            data-testid="box-chart"
            sx={{
              m: "0 auto",
              height: "419px",
              marginRight: '28px',
              textAlign: "center",
            }}
          >
            {
              selectedQuickTimeFilter === 7 ? (
                loading ? (
                  t("loading")
                ) : (
                  <DashboardBarChart
                    width={width}
                    datasets={datasets}
                    labels={label.labelNames}
                  />
                )
              ) : loading ? (
                t("loading")
              ) : (
                <DashboardLineChart
                  width={width}
                  datasets={datasets}
                  labels={label.labelNames}
                />
              )
            }
          </Box>
          <div className="bottton-row" data-testid="bottton-row">
            <Flex data-testid="bottton-row-flex">
              {transactionTypeData &&
                transactionTypeData.filter(
                  t => t.value.toUpperCase() === `ALL`
                    || (transactionAvailabilityByTransactionType as any)[`${t.value.toLowerCase()}`]
                ).map((el, key) => (
                  <ButtonPills
                    key={key}
                    color={el.color}
                    title={t(el.label)}
                    data-testid="btn-pills"
                    handleClick={() => setActive(el.value)}
                    isActive={transactionTypes.toLowerCase() === el.value.toLowerCase()}
                  />
                ))}
            </Flex>
          </div>
        </Card>
        <Flex data-testid="flex-transaction" sx={{ mt: 50, justifyContent: "space-between" }}>
          <Text
            data-testid="flex-transaction-text"
            sx={{ color: "shade_800", fontSize: "heading_4" }}
          >
            {t("Dashboard.recent_transactions")}
          </Text>
          {Object.keys(transactions).length !== 0 && <Text
            data-testid="export"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  Action: "Export",
                  event: "Click_event",
                  Page_title: "Dashboard | Recent Transactions",
                }
              });

              handleDownload();
            }}
            sx={{
              cursor: "pointer",
              fontSize: "subText",
              color: "royalBlue_500",
            }}
          >
            {t("Dashboard.export")}{" "}
            <ExportIcon color={"primary"} data-testid="export-icon" />
          </Text>}
        </Flex>
        <Divider data-testid="divider" />
        {loading || loadingMerchants ? (
          <Loader />
        ) : (
          Object.entries(transactions).map((key: [string, Transaction[]]) => (
            <div key={Math.random()}>
              <Box sx={{ p: "8px 0px" }} data-testid="trans-obj">
                <Text
                  data-testid="trans-obj-key"
                  sx={{
                    pb: "8px",
                    color: "shade_600",
                    fontSize: "subText",
                    fontWeight: "19.6px",
                  }}
                >
                  {key[0]}
                </Text>
              </Box>
              <Card
                bg="white"
                data-testid="trans-wrapper"
                sx={{
                  borderRadius: "8px",
                  padding: "0px 14px 0px 14px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                {key[1].map((el: any, index: number) => {
                  return (
                    <TransactionList transactionData={el} key={index} />
                  )
                })}
              </Card>
            </div>
          ))
        )}
        {
          filterPanel && (
            <FilterOverlay
              setFilterPanel={() => setFilterPanel(false)}
              setIsFiltered={(data) => setIsFiltered(data)}
            />
          )
        }
      </Box>
    </>
  );
}
