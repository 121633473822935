// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";

import { useTranslation } from "react-i18next";
import { Flex, Heading, Button, Box } from "theme-ui";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useLayoutEffect } from "react";
import { useMsal } from "@azure/msal-react";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import OtpModal from "../OtpModal";
import Error from "../../../Shared/Error";
import Loader from "../../../Shared/Loader";
import UserLocations from "./UserLocations";
import PageHeader from "../../../Shared/PageHeader";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import UserLocationsToSelect from "./UserLocationsToSelect";
import UserAccountDetailsAvatar from "./UserAccountDetailsAvatar";

import { Api } from "@brinks/common/api/auth";
import { UserType } from "@brinks/common/constants/users";
import { BackIcon } from "@brinks/common/Icons/Components/back";
import { StoreLocation } from "@brinks/common/api/Api";
import { getCurrentUser } from "../../../utils/secure-storage-utils";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { getUsers, resetState, updateUser, } from "@brinks/common/reducers/userSlice";
import { deleteB2CUser, otpRequest, passwordResetRequest, resetB2CState, updateB2CUser, } from "@brinks/common/reducers/b2cUserSlice";
import { UserLocation, useUserWithLocations } from "../../../Hooks/useUserWithLocations";
import { getDeviceId } from "@brinks/common/utils";
import UserDetails from "../AddNewUser/UserDetails";
import UserAccess from "../AddNewUser/UserAcces";
import { EditIcon } from "@brinks/common/Icons/Components/edit";
import { PhoneType } from "@brinks/common/constants/PhoneType";
import { UserStatus } from "@brinks/common/constants/userStatus";
import ConfirmationModal from "../../ManageOrders/ConfirmationModal";
import SuccessModal from "./SuccessModal";
import { IS_AZURE_B2C_SCHEME } from "@brinks/common/utils/Config";
import useGetUserRole from "../../../Hooks/useGetUserRole";
import logger from "../../../log4jsConfig";


export default function UserAccountDetails() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const { t } = useTranslation();
  //Azure B2C Integration starts here
  const { instance } = useMsal();
  const userRole: string = useGetUserRole(false);

  const [userDetails, setUserDetails] = useState({
    fields: {
      userId: "",
      email: "",
      lastname: "",
      mobileNo: "",
      username: "",
      firstname: "",
      locations: [],
      phoneType: PhoneType.MOBILE,
      type: "",
      status: ""
    },
    errors: {
      email: false,
      lastname: false,
      mobileNo: false,
      username: false,
      firstname: false,
    },
    touched: {},
    formIsValid: false,
  });

  const validateForm = () => {
    const fields: any = { ...userDetails.fields };
    const errors: any = { ...userDetails.errors };

    let isValid: boolean = false;
    if (!errors.firstname && !errors.lastname && !errors.email && !errors.mobileNo && fields.mobileNo !== '') {
      isValid = true
    } else {
      isValid = false
    }
    setUserDetails({ ...userDetails, formIsValid: isValid });
  };
  //Azure B2C Integration ends here

  const {
    filteredUsers,
    allDistinctLocations,
    loading: allUsersLoading,
  } = useUserWithLocations({});

  const { errMsg, success, isFetching, isUpdateUserError } = useAppSelector(
    (state) => state.userSlice

  );

  const { b2cErrMsg, b2cSuccess, isUpdateB2CUserError, isB2cOtpError, b2cOtpSuccess, isPwdRequestError, isDeleteUserError } = useAppSelector(
    (state) => state.b2cUserSlice
  );

  const [isEdit, setIsEdit] = useState(false);
  const [factorId, setFactorId] = useState(null);
  const [otpErrorCount, setOtpErrorCount] = useState(0);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [user, setUser] = useState<null | UserLocation>(null);
  const [isVerifyOtpError, setIsVerifyOtpError] = useState(false);
  const [merchantSearch, setMerchantSearch] = useState<null | string>(null);
  const [locationsSearch, setLocationsSearch] = useState<null | string>(null);
  const [locations, setLocations] = useState<StoreLocation[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<StoreLocation[]>([]);

  const isSuperUser = user?.type === UserType.SUPER_USER ? true : false;
  //Azure B2C Integration starts here
  const [isSuccessModalOpen, toggleSuccessModal] = useState(false);
  //Azure B2C Integration ends here

  const defaultLocationsSelected = () => {
    if (isSuperUser) {
      return allDistinctLocations;
    }

    const user = filteredUsers.find((user) => user.userId === id);

    const userLocations = allDistinctLocations.filter(distinctLocation => {
      return user?.locations.some(userLocation => {
        return distinctLocation.id === userLocation.id;
      });
    });

    return userLocations;
  }
  //Azure B2C Integration starts here
  // validating the super user with OTP before user update  
  const handleEdit = async () => {
    if (userDetails.formIsValid) {

      if (IS_AZURE_B2C_SCHEME) {
        dispatch(otpRequest({}))
          .then((instance) => {
            logger.debug(JSON.stringify({
              Title: "Edit user",
              Instance: instance
            }));
            if (instance.meta.requestStatus === "fulfilled") {
              logger.debug(JSON.stringify({
                Title: "Edit user fulfilled if() condition",
                Instance: instance
              }));
              localStorage.removeItem("msal-popup-access-token");
              handleUpdateB2CUser();
            }
            else if (instance.meta.requestStatus === "rejected" && localStorage.getItem("msal-popup-access-token")) {
              logger.debug(JSON.stringify({
                Title: "Edit user rejected else if() condition",
                Instance: instance
              }));
              dispatch(resetB2CState());
              localStorage.removeItem("msal-popup-access-token");
              handleUpdateB2CUser();
            }
          });
      }
      else {
        sendOtp();
      }
    } else if (
      userDetails.fields.type === UserType.STANDARD_USER &&
      userDetails.fields.locations.length <= 0
    ) {
      const newState: any = { ...userDetails };
      newState.errors.locations = true;
      setUserDetails(newState);
    }
  };

  const handleUpdateB2CUser = () => {
    const mobileNo = userDetails.fields.mobileNo.startsWith("00") ? userDetails.fields.mobileNo.replace("00", "+") : userDetails.fields.mobileNo
    const isMobile = userDetails.fields.phoneType === PhoneType.MOBILE;
    const users =
    {
      userId: userDetails.fields.userId,
      type: userDetails.fields.type,
      firstName: userDetails.fields.firstname,
      lastName: userDetails.fields.lastname,
      email: userDetails.fields.email,
      status: userDetails.fields.status,
      locations: selectedLocations.map(location => location.id),
      ...(isMobile ? { phone: mobileNo.trim() } : { primaryPhone: mobileNo.trim() }),
    };

    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "User management | Add user",
        Action: "User added successfully",
      },
    });
    dispatch(updateB2CUser({ ...users }));

  };
  //Azure B2C Integration ends here

  const validateUser = () => {
    const user = filteredUsers.find((user) => user.userId === id);
    setUser(user ?? null);
    //Azure B2C Integration starts here

    if (!user) {
      return
    }
    else {
      setUserDetails({
        fields: {
          userId: user?.userId?.toString() ?? "",
          firstname: user.firstName,
          lastname: user.lastName,
          email: user.email,
          mobileNo: user?.phone?.toString() ?? user?.primaryPhone?.toString() ?? "",
          username: user?.userId?.toString() ?? "",
          locations: [],
          phoneType: user.primaryPhone?.trim() ? PhoneType.LAND_LINE : PhoneType.MOBILE,
          type: user.type,
          status: user.status == "ACTIVE" ? UserStatus.ACTIVE : UserStatus.INACTIVE
        },
        errors: {
          email: false,
          lastname: false,
          mobileNo: false,
          username: false,
          firstname: false,
        },
        touched: {},
        formIsValid: true,

      });
    }
    //Azure B2C Integration ends here

  }

  //Azure B2C Integration starts here
  useEffect(() => {
    let isSelected = false;
    if (userDetails.fields.type === UserType.SUPER_USER) {
      isSelected = true;
    } else {
      isSelected = false;
    }
    const stores: any =
      selectedLocations &&
      selectedLocations.map((obj: any) => {
        return { ...obj, isSelected: isSelected };
      });
    setSelectedLocations(stores);
    validateForm();
  }, [userDetails.fields.type]);
  //Azure B2C Integration ends here

  useEffect(() => {
    validateUser();
    setSelectedLocations(defaultLocationsSelected());
  }, [user, allDistinctLocations])

  const handleValidateLocationNameAndCode = ({ name, locationCode }: StoreLocation, filter: string): boolean => {
    if (filter && name?.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }

    if (filter && locationCode?.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    let locationsFiltered: StoreLocation[] = allDistinctLocations;

    if (locationsSearch) {
      locationsFiltered = locationsFiltered.filter(location => {
        return handleValidateLocationNameAndCode(location, locationsSearch);
      })
    }

    if (merchantSearch) {
      locationsFiltered = locationsFiltered.filter(location => {
        return location.merchantId === merchantSearch;
      })
    }

    setLocations(locationsFiltered);
  }, [
    merchantSearch,
    locationsSearch,
    allDistinctLocations
  ])

  const sendOtp = async () => {
    setOtpErrorCount(0);
    setIsVerifyOtpError(false);

    const users = getCurrentUser();
    const data: any = { userId: users.id };

    try {
      const smsResp: any = await new Api().auth.sendSmsCode(data);
      setFactorId(smsResp?.data.factorId);
    } catch (error) {
      // setIsOtpError(true);
    }
  };

  const verifyOtp = async (otp: any) => {
    setIsVerifyOtpError(false);

    const users = getCurrentUser();

    const data: any = {
      userId: users.id,
      securityCode: otp,
      factorId: factorId,
    };

    const params: any = {
      headers: {
        "device-id": getDeviceId(),
      },
    };

    setIsVerifyingOtp(true);
    try {
      const res = await new Api().auth.exchangeFactor(data, params);

      if (res.data.token) {
        localStorage.setItem("mfaToken", res.data.token);
        setIsVerifyingOtp(false);
        const updatedUser: any = { ...user };
        updatedUser.phone = user?.phone ? user.phone : ''
        updatedUser.locations = selectedLocations.map(location => location.id);
        dispatch(updateUser(updatedUser));
        dispatch(getUsers({}));
        setOpenOtpModal(false);
      }
    } catch (error) {
      setIsVerifyOtpError(true);
      setIsVerifyingOtp(false);
      setOtpErrorCount(otpErrorCount + 1);
    }
  };

  useEffect(() => {
    if (userRole === UserType.STANDARD_USER) {
      navigate(`/`);
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setOpenOtpModal(false);
  }, [isUpdateUserError])

  useLayoutEffect(() => {
    if (success) {
      dispatch(resetState());
      setOtpErrorCount(0);
      navigate(`/users`);
    }
  }, [success]);

  //Azure B2C Integration starts here
  useLayoutEffect(() => {
    if (b2cSuccess) {
      dispatch(resetB2CState());
      navigate(`/users`);
    }
  }, [b2cSuccess]);

  useLayoutEffect(() => {
    if (b2cOtpSuccess) {
      dispatch(resetB2CState());
    }
  }, [b2cOtpSuccess]);
  //Azure B2C Integration ends here

  const handleSelectLocation = (id: string, isSelected: boolean) => {
    if (isSelected) {
      const selectedLocation = allDistinctLocations.find((location) => location.id === id);
      selectedLocation && setSelectedLocations([...selectedLocations, selectedLocation]);
    }
    else {
      const newSelectedLocations = selectedLocations.filter((location) => location.id !== id);
      setSelectedLocations(newSelectedLocations);
    }
  };
  //Azure B2C Integration starts here
  // passowrd request api call to send email
  const sendPasswordResetRequestMail = (userId: string) => {
    dispatch(otpRequest({}))
      .then((instance) => {
        logger.debug(JSON.stringify({
          Title: "Passowrd reset request",
          Instance: instance
        }));
        if (instance.meta.requestStatus === "fulfilled") {
          logger.debug(JSON.stringify({
            Title: "Passowrd reset request fulfilled if() condition",
            Instance: instance
          }));
          localStorage.removeItem("msal-popup-access-token");
          dispatch(passwordResetRequest({ userId })).then((res: any) => {
            logger.debug(JSON.stringify({
              Title: "Passowrd reset request fulfilled res",
              Result: res
            }));
            if (res.payload.data.message === "success") {
              toggleSuccessModal(true);
            }
          });
        }
        else if (instance.meta.requestStatus === "rejected" && localStorage.getItem("msal-popup-access-token")) {
          logger.debug(JSON.stringify({
            Title: "Passowrd reset request rejected else if() condition",
            Instance: instance
          }));
          dispatch(resetB2CState());
          localStorage.removeItem("msal-popup-access-token");
          dispatch(passwordResetRequest({ userId })).then((res: any) => {
            logger.debug(JSON.stringify({
              Title: "Passowrd reset request rejected popup-token-present res",
              Result: res
            }));
            if (res.payload.data.message === "success") {
              toggleSuccessModal(true);
            }
          });
        }
      });
  }

  const handleDeleteUser = async () => {
    const userId = userDetails.fields.userId;
    dispatch(deleteB2CUser({ userId }))
      .then(res => {
        navigate('/users');
      }).catch(err => {
        <Error message={err.message} />
      });

  }

  const [isConfirmationModalOpen, toggleConfirmationModal] = useState(false);
  //Azure B2C Integration ends here

  if (!user || isFetching || allUsersLoading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <PageHeader>
        <Flex
          sx={{
            width: "75%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Heading>
            <button
              onClick={(e: any) => window.history.back()}
              style={{ marginRight: "20px", background: "transparent", border: "none", cursor: "pointer" }}
            >
              <BackIcon color={"black"} />
            </button>

            {t("Header.user_account")}
          </Heading>

          {
            //Azure B2C Integration starts here
            IS_AZURE_B2C_SCHEME ?
              !isEdit && (
                <Flex sx={{ width: instance.getActiveAccount()?.localAccountId === userDetails.fields.userId ? "35%" : "50%", justifyContent: "space-between" }}>
                  {
                    instance.getActiveAccount()?.localAccountId === userDetails.fields.userId ? <> </> :
                      <Button
                        onClick={() => {
                          TagManager.dataLayer({
                            dataLayer: {
                              Action: "Delete user",
                              event: "Click_event",
                              Page_title: "User management | User account details",
                            },
                          });
                          dispatch(otpRequest({})).then((instance) => {
                            logger.debug(JSON.stringify({
                              Title: "Delete user",
                              Instance: instance
                            }));
                            if (instance.meta.requestStatus === "fulfilled") {
                              logger.debug(JSON.stringify({
                                Title: "Delete user fulfilled if() condition",
                                Instance: instance
                              }));
                              localStorage.removeItem("msal-popup-access-token");
                              toggleConfirmationModal(true);
                            }
                            else if (instance.meta.requestStatus === "rejected" && localStorage.getItem("msal-popup-access-token")) {
                              logger.debug(JSON.stringify({
                                Title: "Delete user rejected else if() condition",
                                Instance: instance
                              }));
                              dispatch(resetB2CState());
                              localStorage.removeItem("msal-popup-access-token");
                              toggleConfirmationModal(true);
                            }
                          });

                        }}
                        sx={{
                          py: 16,
                          px: 12,
                          width: 164,
                          height: 48,
                          borderRadius: 40,
                          color: "red",
                          border: "1px solid",
                          backgroundColor: "white",
                          borderColor: "raspberry_200",
                          boxShadow: " 0px 8px 16px rgba(0, 0, 0, 0.1)",
                          pointerEvents: `${user && user?.status && (
                            `${user.status}` === UserStatus.INACTIVE
                          )
                            ? "none"
                            : "auto"
                            }`,
                        }}
                      >
                        {t("UserAccount.delete_user")}
                      </Button>
                  }

                  <Button
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: {
                          Action: "Reset Password Request",
                          event: "Click_event",
                          Page_title: "User management | User account details",
                        },
                      });
                      sendPasswordResetRequestMail(userDetails.fields.userId);
                    }}
                    sx={{
                      py: 16,
                      px: 12,
                      width: 164,
                      height: 48,
                      borderRadius: 40,
                      color: "primary",
                      border: "1px solid",
                      backgroundColor: "white",
                      borderColor: "shade_200",
                      boxShadow: " 0px 8px 16px rgba(0, 0, 0, 0.1)",
                      pointerEvents: `${user && user?.status && (
                        `${user.status}` === UserStatus.INACTIVE
                      )
                        ? "none"
                        : "auto"
                        }`,
                    }}
                  >
                    {t("UserAccount.password_reset")}
                  </Button>
                  <Button
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: {
                          Action: "Edit user",
                          event: "Click_event",
                          Page_title: "User management | User account details",
                        },
                      });

                      setIsEdit(true);
                    }}
                    variant={`${user && user?.type && (
                      `${user.type}` === UserType.DEACTIVATED
                      || `${user.type}` === UserType.DEPROVISIONED
                    )
                      ? "gray"
                      : "primary"
                      }`}
                    sx={{
                      py: 16,
                      px: 12,
                      width: 164,
                      height: 48,
                      borderRadius: 40,
                      color: "primary",
                      border: "1px solid",
                      backgroundColor: "white",
                      borderColor: "shade_200",
                      boxShadow: " 0px 8px 16px rgba(0, 0, 0, 0.1)",
                      pointerEvents: `${user && user?.type && (
                        `${user.type}` === UserType.DEACTIVATED
                        || `${user.type}` === UserType.DEPROVISIONED
                      )
                        ? "none"
                        : "auto"
                        }`,
                    }}
                  >
                    {t("UserAccount.edit_user")}
                  </Button>
                </Flex>
              )
              //Azure B2C Integration ends here
              :
              !isSuperUser && !isEdit && (
                <Button
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        Action: "Edit user",
                        event: "Click_event",
                        Page_title: "User management | User account details",
                      },
                    });

                    setIsEdit(true);
                  }}
                  variant="ghost"
                  sx={{
                    height: 48
                  }}
                >
                  {t("UserAccount.edit_user")}<EditIcon color={"currentColor"} />
                </Button>
              )
          }

          {
            isEdit && (
              <Flex sx={{ width: "25%", justifyContent: "flex-end", gap: 2 }}>
                <Button
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        Action: "Cancel",
                        event: "Click_event",
                        Page_title: "User management | Update user account",
                      },
                    });

                    setIsEdit(false);
                  }}
                  sx={{
                    height: 48
                  }}
                  variant="ghost"
                >
                  {t("UserAccount.cancel")}
                </Button>
                <Button
                  disabled={selectedLocations.length === 0}
                  variant={`${selectedLocations.length === 0 ? "gray" : "default"}`}
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        Action: "Save",
                        event: "Click_event",
                        Page_title: "User management | Update user account",
                      },
                    });
                    IS_AZURE_B2C_SCHEME ? setOpenOtpModal(false) : setOpenOtpModal(true);
                    handleEdit();
                  }}
                  sx={{
                    height: 48,
                  }}
                >
                  {t("UserAccount.save")}
                </Button>
              </Flex>
            )
          }
        </Flex>
      </PageHeader>

      {
        //Azure B2C Integration starts here
        IS_AZURE_B2C_SCHEME ?
          <Wrapper>
            {!isEdit && <UserAccountDetailsAvatar {...user} />}
            {isEdit && (
              <>
                <UserDetails
                  state={userDetails}
                  handleState={(key: string, value: string) => {
                    const newState: any = { ...userDetails };
                    newState.fields[key] = value;
                    if (newState.fields[key]) {
                      if (key === "email") {

                        const pattern = new RegExp(
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );
                        if (!pattern.test(newState.fields[key])) {
                          newState.errors[key] = true;
                        } else {
                          newState.errors[key] = false;
                        }
                      } else if (key === "mobileNo") {
                        const pattern = new RegExp(
                          /^\+[1-9]{1}[0-9]{6,15}$/)
                        if (!pattern.test(newState.fields[key].trim())) {
                          newState.errors[key] = true;
                        } else {
                          newState.errors[key] = false;
                        }
                      } else {
                        newState.errors[key] = false;
                      }
                    } else {
                      newState.errors[key] = true;
                    }
                    setUserDetails(newState);
                    validateForm();
                  }}
                  handleTouch={(key: string, value: string) => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "Click_event",
                        Page_title: "User management | Add user",
                        Action: "User details - " + key,
                      },
                    });
                    const newState: any = { ...userDetails };
                    if (key && newState.touched[key] !== true) {
                      newState.touched[key] = true;
                      setUserDetails(newState);
                      validateForm();
                    }
                  }}
                />
                <UserAccess
                  state={userDetails}
                  handleState={(key: string, value: string) => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "Click_event",
                        Page_title: "User management | Add user",
                        Action: "User Access - " + value,
                      },
                    });
                    const newState: any = { ...userDetails };
                    newState.fields[key] = value;
                    if (value === UserType.SUPER_USER) {
                      setSelectedLocations(allDistinctLocations);
                    } else {
                      setSelectedLocations([]);
                    }
                    setUserDetails(newState);
                  }}
                />
              </>
            )}
            {!isEdit && (
              <Heading sx={{ fontSize: "medium", marginTop: "20px" }}>
                {t("UserAccount.assigned_locations")}
              </Heading>
            )}
            {
              !isEdit ?
                <UserLocations
                  loading={false}
                  userLocations={selectedLocations}
                />
                :
                <UserLocationsToSelect
                  user={userDetails.fields}
                  isEdit={isEdit}
                  loading={false}
                  locationsFiltered={locations}
                  selectedLocations={selectedLocations}
                  searchMerchant={(value) => setMerchantSearch(value)}
                  searchLocation={(value: string) => setLocationsSearch(value)}
                  handleSelectLocation={(id, value) => { handleSelectLocation(id, value) }}
                />
            }
            <ConfirmationModal
              toggleConfirmationModal={toggleConfirmationModal}
              isConfirmationModalOpen={isConfirmationModalOpen}
              submit={handleDeleteUser}
              confirmationMsg={t("UserAccount.please_confirm_delete_user")}
              btnText={t("UserAccount.delete_user")}
            />
            <SuccessModal
              isSuccessModalOpen={isSuccessModalOpen}
              toggleSuccessModal={toggleSuccessModal}
            />
            {isUpdateB2CUserError && <Error message={ b2cErrMsg.code === "400" ? 
              b2cErrMsg?.message?.includes("phone number") ? t("UserAccount.invalid_phoneNumber") : 
              b2cErrMsg?.message?.includes("Locations") ? t("UserAccount.locations_not_updated") :  '' : 
              b2cErrMsg.status === "500" ? t("something_went_wrong") : '' } />}

            {isPwdRequestError && <Error message={ t("UserAccount.email_not_sent") } />}

            {isDeleteUserError && <Error message={ t("UserAccount.user_delete_failure") } />}

            {isB2cOtpError && <Error message={t("otp_cancel_error")} />}
          </Wrapper>
          //Azure B2C Integration ends here
          :
          <Wrapper>
            <UserAccountDetailsAvatar {...user} />
            {!isEdit && (
              <Heading sx={{ fontSize: "medium" }}>
                {t("UserAccount.assigned_locations")}
              </Heading>
            )}
            {
              !isEdit ?
                <UserLocations
                  loading={false}
                  userLocations={selectedLocations}
                />
                :
                <UserLocationsToSelect
                  user={user}
                  isEdit={isEdit}
                  loading={false}
                  locationsFiltered={locations}
                  selectedLocations={selectedLocations}
                  searchMerchant={(value) => setMerchantSearch(value)}
                  searchLocation={(value: string) => setLocationsSearch(value)}
                  handleSelectLocation={(id, value) => { handleSelectLocation(id, value) }}
                />
            }
            <OtpModal
              sendOtp={sendOtp}
              verifyOtp={verifyOtp}
              isFetching={isFetching}
              openOtpModal={openOtpModal}
              otpErrorCount={otpErrorCount}
              isVerifyingOtp={isVerifyingOtp}
              isVerifyOtpError={isVerifyOtpError}
            />
            {isUpdateUserError && <Error message={errMsg} />}
          </Wrapper>
      }
    </>
  );
}
