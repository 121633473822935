import React, { Suspense } from "react";
import "dotenv/config";

import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// @ts-ignore
import ReactModal from "react-modal";
import reportWebVitals from "./reportWebVitals";
import "./i18";
import Loader from "./Shared/Loader";
import { Flex } from "theme-ui";

import { msalConfig } from './msalConfig';
import { AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser';

const pageLoader = (
  <Flex
    sx={{
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Loader />
  </Flex>
);

//Azure B2C Integration starts here
/*
Creates a new instance of the AzureB2C which will be passed to the child component (App.tsx) as props and will be utilized by child component to give the instace to MsalProvider which facilitates the further Authentication process using hooks provided by MSAL.js package for AzureB2C.
*/
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback(event => {
  const eventData = event.payload as AuthenticationResult;

if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount(eventData.account);
    localStorage.setItem('msal-access-token',eventData.accessToken);
  }
});
//Azure B2C Integration ends here


ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>{pageLoader}</div>}>
      <App msalInstance={msalInstance} />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

ReactModal.setAppElement("#root");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
