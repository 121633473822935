// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";

import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Flex, Heading, Button } from "theme-ui";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import OtpModal from "../OtpModal";
import UserAccess from "./UserAcces";
import UserDetails from "./UserDetails";
import Error from "../../../Shared/Error";
import Loader from "../../../Shared/Loader";
import PageHeader from "../../../Shared/PageHeader";
import Wrapper from "../../../Shared/Wrapper/Wrapper";

import { Locations } from "./Locations";
import { Api } from "@brinks/common/api/auth";
import { getDeviceId } from "@brinks/common/utils";
import { UserType } from "@brinks/common/constants/users";
import { PhoneType } from "@brinks/common/constants/PhoneType";
import { StoreLocation } from "@brinks/common/api/Api";
import { getCurrentUser } from "../../../utils/secure-storage-utils";
import { getUsers, saveUsers } from "@brinks/common/reducers/userSlice";
import { getB2CUsers, otpRequest, resetB2CState, saveB2CUsers } from "@brinks/common/reducers/b2cUserSlice";
import { useUserWithLocations } from "../../../Hooks/useUserWithLocations";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { getStoreLocationsWithMerchant } from "@brinks/common/reducers/storeLocationWithMerchantSlice";
import { BackIcon } from "@brinks/common/Icons/Components/back";
import { IS_AZURE_B2C_SCHEME } from "@brinks/common/utils/Config";
import logger from "../../../log4jsConfig";
export const AddNewUser = () => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "User management | User account details",
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "User management | Add user",
      },
    });
    if (IS_AZURE_B2C_SCHEME) {
      dispatch(getB2CUsers({}));
    } else {
      dispatch(getUsers({}));
    }
    dispatch(getStoreLocationsWithMerchant({}));
  }, []);

  const naviagte = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { success, isSaveUserError, errMsg, isFetching } = useAppSelector(
    (state) => state.userSlice
  );
  //Azure B2C Integration starts here
  const { isB2cOtpError, b2cOtpSuccess, b2cErrMsg, isSaveB2CUserError } = useAppSelector(
    (state) => state.b2cUserSlice
  );
  //Azure B2C Integration ends here
  const {
    merchants,
    locationsFiltering,
    allDistinctLocations,
    loading: allUsersLoading,
  } = useUserWithLocations({});

  const [factorId, setFactorId] = useState(null);
  const [isOtpError, setIsOtpError] = useState(false);
  const [otpErrorCount, setOtpErrorCount] = useState(0);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [isVerifyOtpError, setIsVerifyOtpError] = useState(false);
  const [locations, setLocations] = useState<StoreLocation[]>([]);

  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [merchantTerm, setMerchantTerm] = useState<string | null>(null);


  const [state, setState] = useState({
    fields: {
      email: "",
      lastname: "",
      mobileNo: "",
      username: "",
      firstname: "",
      locations: [],
      phoneType: PhoneType.MOBILE,
      type: UserType.STANDARD_USER,
    },
    errors: {
      email: false,
      lastname: false,
      mobileNo: false,
      username: false,
      firstname: false,
    },
    touched: {},
    formIsValid: false,
  });

  const handleLocations = (id: string, value: boolean) => {

    const newLoca: any = [...locations];
    const newState: any = { ...state };
    const index = newLoca?.findIndex((el: any) => el.id === id);
    newLoca[index] = { ...newLoca[index], isSelected: !value }

    const locationIndex = newState.fields.locations.findIndex(
      (el: any) => el === id
    );

    if (newLoca[index].isSelected) {
      newState.fields.locations.push(newLoca[index].id);
    } else {
      newState.fields.locations.splice(locationIndex, 1);
    }

    if (searchTerm) {
      setSearchTerm(searchTerm);
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "User management | Add user",
        Action:
          newLoca[index].isSelected === true
            ? "Assign location"
            : "Unassign location",
      },
    });

    setLocations(newLoca);
    setState(newState);
    validateForm();
  };

  useEffect(() => {
    let isSelected = false;
    if (state.fields.type === UserType.SUPER_USER) {
      isSelected = true;
    } else {
      isSelected = false;
    }
    const stores: any =
      locations &&
      locations.map((obj: any) => {
        return { ...obj, isSelected: isSelected };
      });

    setLocations(stores);
    validateForm();
  }, [state.fields.type]);

  useEffect(() => {
    if (success) {
      if (IS_AZURE_B2C_SCHEME) {
        dispatch(getB2CUsers({}));
      }
      else {
        dispatch(getUsers({}));
      }
      setOtpErrorCount(0);
      naviagte(`/users`);
    }
  }, [success]);

  //Azure B2C Integration starts here
  useLayoutEffect(() => {
    if (b2cOtpSuccess) {
      dispatch(resetB2CState());
    }
  }, [b2cOtpSuccess]);
  //Azure B2C Integration ends here

  const handleActivate = async () => {
    if (state.formIsValid) {
      //Azure B2C Integration starts here
      // validating the super user with OTP before user creation

      if (IS_AZURE_B2C_SCHEME) {
        dispatch(otpRequest({}))
          .then((instance) => {
            logger.debug(JSON.stringify({
              Title: "Add user",
              Instance: instance
            }));
            if (instance.meta.requestStatus === "fulfilled") {
              logger.debug(JSON.stringify({
                Title: "Add user fulfilled if() condition",
                Instance: instance
              }));
              localStorage.removeItem("msal-popup-access-token");
              saveUser();
            }
            else if(instance.meta.requestStatus === "rejected" && localStorage.getItem("msal-popup-access-token")){
              logger.debug(JSON.stringify({
                Title: "Add user else rejected if() condition",
                Instance: instance
              }));
              dispatch(resetB2CState());
              localStorage.removeItem("msal-popup-access-token");
              saveUser();
            }
          });
      }
      //Azure B2C Integration ends here

      else {
        sendOtp();
      }
    } else if (
      state.fields.type === UserType.STANDARD_USER &&
      state.fields.locations.length <= 0
    ) {
      const newState: any = { ...state };
      newState.errors.locations = true;
      setState(newState);
    }
  };

  const sendOtp = async () => {
    setOtpErrorCount(0);
    setIsVerifyOtpError(false);
    const users = getCurrentUser();
    const data: any = {
      userId: users.id,
    };
    try {
      setOpenOtpModal(true);
      const smsResp: any = await new Api().auth.sendSmsCode(data);
      setFactorId(smsResp?.data.factorId);
    } catch (error) {
      setIsOtpError(true);
    }
  };

  const verifyOtp = async (otp: any) => {
    setIsVerifyOtpError(false);
    const users = getCurrentUser();
    const data: any = {
      userId: users.id,
      factorId: factorId,
      securityCode: otp,
    };
    const params: any = {
      headers: {
        "device-id": getDeviceId(),
      },
    };
    setIsVerifyingOtp(true);
    try {
      const res = await new Api().auth.exchangeFactor(data, params);
      if (res.data.token) {
        setOpenOtpModal(false);
        localStorage.setItem("mfaToken", res.data.token);
        setIsVerifyingOtp(false);
        saveUser();
      }
    } catch (error) {
      setIsVerifyOtpError(true);
      setIsVerifyingOtp(false);
      setOtpErrorCount(otpErrorCount + 1);
    }
  };

  const saveUser = () => {
    const mobileNo = state.fields.mobileNo.startsWith("00") ? state.fields.mobileNo.replace("00", "+") : state.fields.mobileNo
    const isMobile = state.fields.phoneType === PhoneType.MOBILE;
    const users = [
      {
        type: state.fields.type,
        firstName: state.fields.firstname,
        lastName: state.fields.lastname,
        email: state.fields.email,
        status: "UNKNOWN",
        locations: state.fields.locations,
        ...(isMobile ? { phone: mobileNo.trim() } : { primaryPhone: mobileNo.trim() }),
      },
    ];
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "User management | Add user",
        Action: "User added successfully",
      },
    });
    if (IS_AZURE_B2C_SCHEME) {
      dispatch(saveB2CUsers({ users }));
    }
    else {
      dispatch(saveUsers({ users }));
    }

  };

  const validateForm = () => {
    const fields: any = { ...state.fields };
    const errors: any = { ...state.errors };

    let isValid: any = false
    if (!errors.firstname && !errors.lastname && !errors.email && !errors.mobileNo && fields.mobileNo !== '' && fields.locations.length > 0) {
      isValid = true
    } else {
      isValid = false
    }
    setState({ ...state, formIsValid: isValid });
  };

  useEffect(() => {
    const newState: any = { ...state };
    newState.fields.type = UserType.STANDARD_USER;
    newState.fields.locations = [];
    setState(newState);
  }, [isSaveUserError]);

  useEffect(() => {
    setLocations(allDistinctLocations);
  }, [allDistinctLocations, allUsersLoading, locationsFiltering])

  useEffect(() => {
    let locationsToFilter: StoreLocation[] = [];
    allDistinctLocations.map((location) => locationsToFilter.push(location));

    if (merchantTerm) {
      locationsToFilter = locationsToFilter.filter((location) => { return location.merchantId === merchantTerm })
    }

    if (searchTerm) {
      locationsToFilter = locationsToFilter.filter((location) => { return handleValidateLocationNameAndCode(location, searchTerm) });
    }

    setLocations(locationsToFilter);
  }, [searchTerm, merchantTerm]);

  const handleValidateLocationNameAndCode = ({ name, locationCode }: StoreLocation, filter: string): boolean => {
    if (filter && name?.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }

    if (filter && locationCode?.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }

    return false;
  }

  if (isFetching || allUsersLoading || locationsFiltering) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <PageHeader>
        <Heading>
          <button
            onClick={(e: any) => window.history.back()}
            style={{ marginRight: "20px", background: "transparent", border: "none", cursor: "pointer" }}
          >
            <BackIcon color={"black"} />
          </button>
          {t("AddNewUser.Heading")}
        </Heading>
      </PageHeader>
      <Wrapper>
        <UserDetails
          state={state}
          handleState={(key: string, value: string) => {
            const newState: any = { ...state };
            newState.fields[key] = value;
            if (newState.fields[key]) {
              if (key === "email") {

                const pattern = new RegExp(
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
                if (!pattern.test(newState.fields[key])) {
                  newState.errors[key] = true;
                } else {
                  newState.errors[key] = false;
                }
              } else if (key === "mobileNo") {
                const pattern = new RegExp(
                  /^\+[1-9]{1}[0-9]{6,15}$/)
                if (!pattern.test(newState.fields[key].trim())) {
                  newState.errors[key] = true;
                } else {
                  newState.errors[key] = false;
                }
              } else {
                newState.errors[key] = false;
              }
            } else {
              newState.errors[key] = true;
            }
            setState(newState);
            validateForm();
          }}
          handleTouch={(key: string, value: string) => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "User management | Add user",
                Action: "User details - " + key,
              },
            });
            const newState: any = { ...state };
            if (key && newState.touched[key] != true) {
              newState.touched[key] = true;
              setState(newState);
              validateForm();
            }
          }}
        />
        <UserAccess
          state={state}
          handleState={(key: string, value: string) => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "User management | Add user",
                Action: "User Access - " + value,
              },
            });
            const newState: any = { ...state };
            newState.fields[key] = value;
            if (value === UserType.SUPER_USER) {
              newState.fields.locations =
                locations && locations.map((el: any) => el.id);
            } else {
              newState.fields.locations = [];
            }
            setState(newState);
          }}
        />
        <Locations
          merchants={merchants}
          locations={locations}
          loading={allUsersLoading}
          searchMerchant={(value) => {
            setMerchantTerm(value);
          }}
          handleLocations={(id, value) => handleLocations(id, value)}
          searchLocation={(value: string) => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Action: "Search location",
                Page_title: "User management | Add user",
              },
            });
            setSearchTerm(value);
          }}
          state={state}
        />

        <Flex mt={29} sx={{ justifyContent: "end" }}>
          <Button
            onClick={(e: any) => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title: "User management | Add user",
                  Action: "Activate",
                },
              });
              handleActivate();
            }}
            variant={`${!state.formIsValid ? "gray" : "primary"}`}
            sx={{
              width: 164,
              height: 48,
              borderRadius: 40,
              pointerEvents: `${!state.formIsValid ? "none" : "auto"}`,
              px: 12,
              py: 16,
              boxShadow: " 0px 8px 16px rgba(0, 0, 0, 0.1)",
            }}
          >
            {t("AddNewUser.activate")}
          </Button>
        </Flex>
        <OtpModal
          openOtpModal={openOtpModal}
          verifyOtp={verifyOtp}
          isVerifyOtpError={isVerifyOtpError}
          otpErrorCount={otpErrorCount}
          isFetching={isFetching}
          isVerifyingOtp={isVerifyingOtp}
          sendOtp={sendOtp}
        />
        {isSaveUserError && <Error message={errMsg?.code === '3002' ? t("AddNewUser.user_exist") : ''} />}
        {isSaveB2CUserError && <Error message={ b2cErrMsg?.code === '400' ?
          b2cErrMsg?.message?.includes("userPrincipalName") ? t("AddNewUser.user_exist") : 
          b2cErrMsg?.message?.includes("phone number") ? t("AddNewUser.invalid_phoneNumber") : 
          b2cErrMsg?.message?.includes("Email") ? t("AddNewUser.user_email_not_sent") : 
          b2cErrMsg?.message?.includes("Locations") ? t("AddNewUser.locations_not_added") : '' : '' } />}
        {isB2cOtpError && <Error message={t("otp_cancel_error")} />}
      </Wrapper>
    </>
  );
}
