/** @jsxImportSource theme-ui */
import { useNavigate } from "react-router-dom";
import { Flex, Button, Card, Text } from "theme-ui";
import { useTranslation } from "react-i18next";
import { ReactComponent as Warning } from "@brinks/common/Icons/warningmsg.svg";
import { PasswordStatus } from "@brinks/common/constants/PasswordStatus";

interface Props {
  status: PasswordStatus;
}

function PasswordExpired({ status }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Card
      data-testid="passwordExpiredPage"
      sx={{
        width: ["100%", "50%"],
        px: ["16px", 0],
        mt: [50, 200],
      }}
    >
      <Flex
        data-testid="passwordExpired-wrapper"
        mt={25}
        sx={{
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Warning />

        <Text
          data-testid="passwordExpired-text"
          mt={25}
          sx={{
            fontWeight: "weight_400",
            color: "shade_600",
            textAlign: "center",
          }}
        >
          {t("Registration.password_expired")}
        </Text>

        <Button
          data-testid="successPage-btn"
          mt={50}
          value="Submit"
          onClick={handleClick}
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 50,
            width: 264,
            borderRadius: "40px",
            cursor: "pointer",
          }}
        >
          Ok
        </Button>
      </Flex>
    </Card>
  );
}

export default PasswordExpired;
