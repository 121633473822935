import log4js from "log4js";
import logglyAppender from './logglyAppender';

log4js.configure({
  appenders: {
    loggly: {                            // Loggly appender
      type: logglyAppender,
    },
  },
  categories: {
    default: { appenders: ['loggly'], level: 'debug' },  // Default category
  },
});

const logger = log4js.getLogger();

export default logger;
