/** @jsxImportSource theme-ui */

import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  Flex,
  Box,
  Label,
  Text,
  Input,
  Button,
  Heading,
  Card,
  useColorMode,
} from "theme-ui";
import useTheme from "../../../Hooks/useTheme";
import Logo from "@brinks/common/Icons/Logo";
import LeftSidePage from "../LeftSidePage";
import LanguageSelector from "../LanguageSelector";
import LoginErrorCard from "./LoginErrorCard";
import { exchangeToken } from "@brinks/common/reducers/authSlice";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import Otp from "./Otp";
import { ErrorIcon } from "@brinks/common/Icons/Components/error";
import { useOktaAuth } from "@okta/okta-react";
import Loader from "../../../Shared/Loader";
import { saveCurrentUser } from "../../../utils/secure-storage-utils";

function Login() {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Sign in | Sign in to BLUbeem",
      },
    });
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showOtp, setIsShowOtp] = useState(false);
  const [isValid, setIsvalid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const { t } = useTranslation();
  const themeType = useTheme();
  const { authState, oktaAuth } = useOktaAuth();

  const [colorMode, setColorMode] = useColorMode();

  // const apiService = new ApiService(email, password, oktaAuth);
  const { isError, accessTokenData, tokenData, loading } = useAppSelector(
    (state) => state.authSlice
  );
  const [isTokenError, setIsTokenError] = useState(isError);

  const handleClick = () => {
    navigate("/forgot-password");
  };
  const handleSubmit = async () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Sign in | Sign in to BLUbeem",
        Action: "Confirm",
      },
    });
    if (!email || !password) {
      setIsvalid(true);
      return;
    }
    const pattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!pattern.test(email)) {
      setIsEmailValid(true);
      return;
    }
    setIsEmailValid(false);
    setIsvalid(false);
    const data = {
      username: email,
      password: password,
      options: {
        multiOptionalFactorEnroll: true,
        warnBeforePasswordExpired: false,
      },
    };

    dispatch(exchangeToken(data));
  };

  useEffect(() => {
    if (tokenData?.stateToken) {
      saveCurrentUser(tokenData?._embedded.user);
      setIsShowOtp(true);
    } else {
    }
  }, [tokenData?.stateToken]);

  if (authState && authState?.isAuthenticated) {
    return <Navigate to="/" replace state={{ path: location.pathname }} />;
  }

  return (
    <Flex
      data-testid="SetPassword-wrapper"
      sx={{
        alignItems: "center",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <LeftSidePage />
      {tokenData?.errorId || accessTokenData?.errorId ? (
        <LoginErrorCard msg={""} />
      ) : showOtp ? (
        <Otp />
      ) : (
        <Flex
          data-testid="SetPassword-wrapper-lang"
          bg={"white"}
          sx={{
            width: ["100%", "60%"],
            height: "100vh",
            alignItems: "center",
            flexDirection: "column",
            margin: "0 auto",
            overflowY: "scroll",
          }}
        >
          <Flex
            data-testid="SetPassword-wrapper-lang-text"
            sx={{ alignSelf: "flex-end" }}
            mr={31}
            mt={30}
          >
            <LanguageSelector />
          </Flex>
          <Flex sx={{ display: ["block", "none"], mt: 50 }}>
            <Logo themeType={themeType} />
          </Flex>
          <Card
            sx={{
              width: ["100%", "50%"],
              px: ["16px", 0],
              mt: [50, 200],
            }}
            data-testid="setPassword-card"
          >
            <Box
              data-testid="setPassword-card-heading"
              mt={25}
              sx={{
                flexDirection: "column",
              }}
            >
              <Heading
                data-testid="setPassword-card-heading-text"
                color="royalBlue_700"
              >
                {colorMode === "brinks"
                  ? t("Registration.brinks_sign_in")
                  : t("Registration.sign_in")}
              </Heading>
              <Box mt={40} data-testid="setPassword-card-input-wrapper">
                <Label
                  data-testid="setPassword-card-input-label"
                  color="shade_600"
                  sx={{ lineHeight: "27.2px" }}
                >
                  {t("Registration.email_address")}
                </Label>
                <Input
                  data-testid="setPassword-card-input-value"
                  type="email"
                  value={email}
                  placeholder={t("Registration.email_address")}
                  autoFocus={true}
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                    setIsvalid(false);
                  }}
                  required={true}
                />
              </Box>
              <Box mt={20} data-testid="setPassword-card-input-wrapper-2">
                <Label
                  data-testid="setPassword-card-input-wrapper-label-2"
                  color="shade_600"
                  sx={{ lineHeight: "27.2px" }}
                >
                  {t("Registration.password")}
                </Label>
                <Input
                  data-testid="setPassword-card-input-wrapper-label-value"
                  type="password"
                  value={password}
                  placeholder={t("Registration.password")}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                    setIsvalid(false);
                  }}
                  required={true}
                />
              </Box>
              {isValid && (
                <Flex pt={2} data-testid="OtpPage-card-error-flex">
                  <>
                    <ErrorIcon
                      color={"raspberry_500"}
                      data-testid="OtpPage-card-error-icon"
                    />
                    <Text
                      data-testid="OtpPage-card-invalid-code"
                      pl={2}
                      color="raspberry_500"
                      sx={{
                        fontSize: "subText",
                        fontWeight: "weight_400",
                      }}
                    >
                      <>
                        {t("Registration.login_validation_msg")}
                        {}
                      </>
                    </Text>
                  </>
                </Flex>
              )}
              {isEmailValid && (
                <Flex pt={2} data-testid="OtpPage-card-error-flex">
                  <>
                    <ErrorIcon
                      color={"raspberry_500"}
                      data-testid="OtpPage-card-error-icon"
                    />
                    <Text
                      data-testid="OtpPage-card-invalid-code"
                      pl={2}
                      color="raspberry_500"
                      sx={{
                        fontSize: "subText",
                        fontWeight: "weight_400",
                      }}
                    >
                      <>{t("Registration.incorrect_email")}</>
                    </Text>
                  </>
                </Flex>
              )}
              <Flex
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: ["column", "row"],
                  order: [2, 1],
                }}
                mt={30}
              >
                <Box
                  sx={{
                    order: [2, 1],
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                >
                  <Text
                    ml={2}
                    color="royalBlue_500"
                    sx={{
                      fontWeight: "weight_400",
                      lineHeight: "27.2px",
                      textAlign: "center",
                    }}
                  >
                    {t("Registration.forgot_password")}
                  </Text>
                </Box>
                <Button
                  variant={`${loading ? "gray" : "primary"}`}
                  data-testid="setPassword-card-submit-btn"
                  onClick={() => handleSubmit()}
                  value="Submit"
                  disabled={loading}
                  sx={{
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                    height: 49,
                    width: ["100%", "169px"],
                    order: [1, 2],
                    pointerEvents: `${loading ? "none" : "auto"}`,
                    borderRadius: "40px",
                    cursor: "pointer",
                  }}
                >
                  {t("Registration.login")}
                </Button>
              </Flex>
            </Box>
          </Card>
        </Flex>
      )}
    </Flex>
  );
}

export default Login;
