import axios from 'axios';
import { AppenderFunction, AppenderModule, LoggingEvent } from 'log4js';

// Loggly API endpoint (replace with your Loggly token)
const LOGGLY_URL = 'https://logs-01.loggly.com/inputs/c8c7cc0b-92f4-40b2-b0a2-339ef1327826/tag/http/';

// Define the custom Loggly appender function
const logglyAppender: AppenderModule = {
  configure(config, layouts, findAppender, levels): AppenderFunction {
    // Return the appender function that will process the logging event
    return (loggingEvent: LoggingEvent) => {
      const logPayload = {
        message: loggingEvent.data.join(' '),  // Combine message parts
        level: loggingEvent.level.levelStr,    // Log level (INFO, ERROR, etc.)
        timestamp: new Date().toISOString(),  // Timestamp for log
        logger: loggingEvent.context || 'unknown', // Optional logger context
      };

      // Send the log to Loggly via POST request
      axios.post(LOGGLY_URL, logPayload)
        .then(response => {
          console.log('Log sent to Loggly:', response.data);
        })
        .catch(error => {
          console.error('Error sending log to Loggly:', error);
        });
    };
  },
};

export default logglyAppender;
